import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgTitleImpressum from "../images/page_title_impressum.png"

const Impressum = props => {
  return (
    <Layout>
      <Seo title="impressum" />
      <section className="impressum container">
        <img
          className="page-title"
          src={imgTitleImpressum}
          alt="kaffeerösterei in peine"
        />

        <p>
          <strong>impressum verantwortlich gem. §6tdg:</strong>
          <br />
          das kleine röstwerk
          <br />
          thorsten tessmer
          <br />
          heinrich-hertz-straße 7 · 31228 peine
          <br />
          telefon: 05171 5083 - 70
          <br />
          telefax: 05171 5083 - 69
          <br />
          e-mail: info@daskleineroestwerk.de
          <br />
          internet: www.daskleineroestwerk.de
        </p>

        <p>ust-idnr. de 264350972</p>

        <p>
          <strong>inhalte</strong>
          <br />
          alle in diesen internetseiten enthaltenen angaben und informationen
          sind nach bestem wissen zusammengestellt und überprüft. diese
          informationen sind ein service vom betreiber der website. eine
          gewährleistung für richtigkeit, vollständigkeit und aktualität kann
          nicht übernommen werden. weder der betreiber der website, noch dritte
          können die haftung für schäden übernehmen, die im zusammenhang mit der
          verwendung dieser inhalte stehen.
          <br />
          alle angaben und informationen dienen ausschließlich der information
          der besucher dieser internetseiten. im übrigen ist die haftung auf
          vorsatz und grobe fahrlässigkeit beschränkt. soweit durch verlinkung
          auf internetseiten dritter weitere informationen zugänglich gemacht
          werden, bzw. die internetseiten vom betreiber der website ohne wissen
          von einer anderen website durch verlinkung zugänglich gemacht werden,
          weist der betreiber der website darauf hin, dass die jeweiligen
          anbieter die verantwortung tragen. der betreiber der website ist für
          darstellung, inhalt oder irgendeine verbindung zu internetseiten
          dritter nicht verantwortlich. auf diesen internetseiten kommen marken
          und geschäftliche bezeichnungen zur anwendung. selbst wenn diese nicht
          entsprechend gekennzeichnet sind, gelten die entsprechenden
          schutzbestimmungen. urheberrechtliche hinweise die inhalte unserer
          internetseiten sind urheberrechtlich geschützt. nur die zur
          weiterverwendung ausdrücklich freigegebenen inhalte dürfen von unseren
          internetseiten heruntergeladen und vervielfältigt werden. hierbei
          dürfen die inhalte weder verändert, noch verfälscht werden, soweit
          nicht jeweils im einzelnen ausdrücklich erlaubt. die erstellung einer
          angemessenen anzahl von kopien auf elektronischen datenträgern oder im
          print-verfahren ist zulässig, soweit diese kopien ausschließlich zum
          persönlichen gebrauch erstellt und genutzt werden. der betreiber der
          website ist als quelle jedoch ausdrücklich zu nennen, soweit nicht
          jeweils im einzelnen ausdrücklich ausgenommen. jede weitergehende
          nutzung der inhalte unserer internetseiten (z. b. texte und
          abbildungen), auch auszugsweise, ist ohne die schriftliche zustimmung
          vom betreiber der website urheberrechtswidrig und strafbar.
          <br />
          das kleine röstwerk
        </p>
      </section>
    </Layout>
  )
}

export default Impressum
